import React from "react"
import { Link } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"

import styled from 'styled-components';

const Box = styled.div`
  @media screen and (min-width:768px){
    &:before {
      content:'';
      position:absolute;
      top:-1000px;
      bottom:-1000px;
      left:0;
      width:10000px;
      background:#BCD9DF;
    }

  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Not found | Greenbridge" />
    <section className="lg:py-32 md:py-28 py-16 overflow-hidden max-w-screen text-center md:text-left">
      <div className="o-wrapper">
        <div className="grid md:grid-cols-2 gap-8 items-center">

          <Box className="md:order-1 relative hidden md:block" style={{left: '5%'}}>
            <div className="bg-white md:py-28 relative z-10" style={{ maxWidth: '520px' }}>
              <svg className="mx-auto md:ml-0" style={{maxWidth:'85%'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 409.772 158.952"><path d="M108.78 157.176V124.32h19.98V98.79h-19.98V1.776H76.812L0 98.79v25.53h79.476v32.856zM79.476 98.79H29.082l50.394-61.938zm130.516 60.162c38.628 0 56.388-26.64 56.388-74.148V74.148C266.38 26.64 248.62 0 209.992 0h-8.88c-38.628 0-56.166 26.64-56.166 74.148V84.8c0 47.508 17.538 74.148 56.166 74.148zm-1.332-25.53h-6.216c-17.538 0-28.194-13.542-28.194-40.848v-26.2c0-27.528 10.656-41.07 28.194-41.07h6.216c17.538 0 28.416 13.542 28.416 41.07v26.2c0 27.306-10.876 40.848-28.416 40.848zm181.132 23.754V124.32h19.98V98.79h-19.98V1.776h-31.968L281.012 98.79v25.53h79.476v32.856zm-29.3-58.386h-50.398l50.394-61.938z"/></svg>
            </div>
          </Box>

          <div className="">
            <h1 className="u-h1 mb-5">That page can't be found</h1>
            <p className="mb-6">Let's get you back somewhere safe</p>

            <Link to="/" className="c-btn inline-block bg-black text-white">Take me home</Link>
          </div>

        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
